import './App.scss';
import Sidebar from './components/Sidebar';
import {
  Route,
  Routes,
} from "react-router-dom";
import Home from './pages/Home';
import Details from './pages/Details';

function App() {

  return (
    <div className="App">
      <Sidebar />
      <main className='page-content'>
      <Routes >
        <Route path='/' element={<Home />} />
        <Route path='details' element={<Details />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
