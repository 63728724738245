import { Row, Col, Badge, Button, Dropdown } from "react-bootstrap";
import {
  IoCallOutline,
  IoMailOutline,
  IoPlaySkipBackOutline,
  IoPlaySkipForwardOutline
} from "react-icons/io5";
import { RiUserReceived2Line, RiSpamLine } from "react-icons/ri";
import { HiOutlineExternalLink } from "react-icons/hi";
import { TiPencil } from "react-icons/ti";
import { BsStars } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { Skeleton } from "@mui/material";

const categories = [
  "Account Support",
  "Other",
  "Tech Support Level 1",
  "General enquiries",
  "VoIP Service Issue",
  "Installation",
  "Network Build",
  "Broadband Service Issue",
  "Billing",
  "Activation",
  "Pre-live Cancellation",
  "RFSI",
  "ABTN",
  "Sales and Orders",
  "Live Termination",
  "No answer",
  "Call Transfer",
  "Transferred to different department",
  "Partnered Products",
  "Recontract",
  "Voucher Query"
];

const subCategories = [
  "Information Request",
  "Portal Support",
  "Third Party Contact",
  "Connection Drop (Level 1)",
  "Account Support",
  "Porting",
  "Wrong Number Dialled",
  "Speed Issues (Level 1)",
  "No Connection",
  "Other",
  "Installation Guidelines",
  "Access/Notification",
  "Workmanship",
  "Basic Support",
  "User Issue",
  "Service",
  "Change of Details",
  "Unplanned Outage",
  "Book/Amend Install",
  "Set Up Issue",
  "Invoice",
  "Self Activation",
  "Credit Control",
  "Goodwill Request",
  "Pre-Live Cancellation",
  "Blocked Duct",
  "Wayleave",
  "Missing Pot",
  "Retentions/Cancellation",
  "Business customer",
  "Stumped Fibre",
  "Fibre Break",
  "New Order",
  "Site Survey",
  "Change of terms",
  "Email sent",
  "Damage",
  "Optics Present â€“ No Connection",
  "Billing",
  "Direct Debit",
  "Another supplier",
  "Customer Loyalty",
  "Residential customer",
  "Wifi Coverage",
  "CPE Issues",
  "Speed Issues",
  "COPS",
  "Netgem",
  "Equipment Installation",
  "Self Activation Issue",
  "ARPU increase",
  "No Optics",
  "Offer and Promotion",
  "Intermittent Connection",
  "Third Party Device/Software Support",
  "Additional Equipment",
  "NTE Issues",
  "ARPU constant",
  "Price",
  "Broadband issue",
  "Connection Drop (Level 2)",
  "Left VM",
  "Changed mind",
  "IP Request",
  "Static IP Issue",
  "Sales",
  "No VM left",
  "Hazard",
  "Customer dispute",
  "Pot Relocation",
  "Early Termination Charge",
  "Customer Support",
  "Mis Network Issues",
  "Activation Issue",
  "Upgrade/Downgrade",
  "Update on Order",
  "Install issue",
  "Moved House - Out of Gigaclear area",
  "Voucher Query",
  "ARPU decrease"
];

const caseOwners = [
  "Abbie Wiltshire",
  "Abby Irvine",
  "Alekzandra Auton",
  "Alfie Steptoe Smith",
  "Alister Eaton",
  "Amalia Flanagan",
  "Amelia Macleod",
  "Amy Ricketts",
  "Ansaar Mohammed",
  "Anthony Dawson",
  "Aoife Burnett",
  "Aron McIver",
  "Baily Cunningham",
  "Ben Weller",
  "Benedict Mcgonigal",
  "Benjamin Mulley",
  "Beth Davies",
  "Bethan Griffin",
  "Brendan Ellis",
  "Brian Mhlanga",
  "Bromley Elford",
  "Caitlin Tanner",
  "Callum Williams",
  "Callun Coles",
  "Cameron Cording",
  "Charles Bennett",
  "Charlie Hooley",
  "Charlotte Duxbury-Watkinson",
  "Chris Burt",
  "Cleo Maddison",
  "Clifton Gaughan",
  "Coral Stanley",
  "Daniel Cadman",
  "Daniel Drew",
  "Daniel Siefers",
  "Daniel Thomson",
  "Daniella Cardona",
  "Danielle Hilton",
  "Dannii Laine",
  "Dean Saunders",
  "Dominic Stickley",
  "Ella Ward",
  "Emily Collier",
  "Emily Green",
  "Emily Lewis",
  "Emily Thomas",
  "Ephraim Tiako",
  "Erwan Bacquet",
  "Faruk Alam",
  "Felipe Lemos",
  "Gabriel Oliveira",
  "Gemma Gerring",
  "Geo Ratouly",
  "Grace Thomas",
  "Graham Yates",
  "Grant Harris",
  "Hamza Ali",
  "Hamza Younis",
  "Harley Harrison",
  "Harley Hayward",
  "Harry Chambers",
  "Harry Hatherall",
  "Hayden Robins",
  "Heidi Fielding",
  "Hollie Pitts",
  "Houston McDowall",
  "Ione August",
  "Isis Rivas",
  "Jack Lakin",
  "Jacob Merrison",
  "Jake Jones",
  "James King",
  "James Sciberras",
  "Jazmine Hutchinson",
  "Jess Greenaway",
  "Jessica Ross",
  "Jesslyn Murigu",
  "Joseph Drinkwater",
  "Joshua Yates",
  "Julia Kobet",
  "Kaelem Jackson",
  "Kanchan Singh",
  "Karl Huggins",
  "Karthik Rudrappa",
  "Kashif Sayed",
  "Kayla Bentley",
  "Kayleigh Hannington",
  "Kowshik Ahmed",
  "Kyle Garrett",
  "Laura Mulroney",
  "Leanne Revell",
  "Lewis Wakefield",
  "Liam Rowntree",
  "Lily Doherty",
  "Lily Pearce",
  "Louisa Canlan",
  "Maciej Najda",
  "Madison Purves",
  "Maizi Bound",
  "Maja Piotrowska",
  "Manasseh Tiako",
  "Mara Amicucci",
  "Marcos De La Prida",
  "Matthew Bricknell",
  "Matthew Davies",
  "Matthew Davis",
  "Maurice Huggins",
  "Melaia Tamanitoakula",
  "Mia Strugar",
  "Michael St John",
  "Michaella Curtis",
  "Millie Snowdon",
  "Milly Lunn",
  "Nahidul Kamalee",
  "Najma Shamow",
  "Natasha Dumelow",
  "Nazmin Miah",
  "Nick Collis",
  "Nicole Clent",
  "Noreen Shepley",
  "Ollie Mawby",
  "Oscar Hally",
  "Owain Worgan",
  "Owen Pettis",
  "Papiya Singh",
  "Perry Milligan",
  "Rachael Delohery-Duffy",
  "Rachel Neal",
  "Rafael Ferreira",
  "Raneen Ali",
  "Rebecca Davis",
  "Robert Pont",
  "Robert Taplin",
  "Rose Odonnell",
  "Ryan Dowling",
  "Ryan Slater",
  "Ryan Spencer-Matthews",
  "Sachin Dengri",
  "Sage Waddacor",
  "Sam Barwani",
  "Sam Drake",
  "Sam Tolson",
  "Sandesh Thapa",
  "Sannie Babe",
  "Scarlett Gilpin",
  "Seamus Cahill",
  "Sharon Howse",
  "Shoaib Araf",
  "Sofia Buckingham",
  "Sonny Florey",
  "Sorrel Hanlon",
  "SysAdmin",
  "Tajul Islam",
  "Tiago Varela",
  "Tim Dalby",
  "Torrey Saint",
  "Ujawal Limbu",
  "Victoria Louden-Carter",
  "Yani Zahrir"
];

const Details = () => {
  const location = useLocation();
  const [data, setData] = useState({});

  const [category, setCategory] = useState("Select Category");
  const [predictedCategory, setPredictedCategory] = useState({
    label: "",
    score: 0
  });
  const [aiCategory, setAICategory] = useState(false);
  const [catLoading, setCatLoading] = useState(true);

  const [subCategory, setSubCategory] = useState("Select Sub Category");
  const [predictedSubCategory, setPredictedSubCategory] = useState({
    label: "",
    score: 0
  });
  const [aiSubCategory, setAISubCategory] = useState(false);
  const [subCatLoading, setSubCatLoading] = useState(true);

  const [isDSAR, setIsDSAR] = useState(false);
  const [predictedDSAR, setPredictedDSAR] = useState({ label: "", score: 0 });
  const [aiDSAR, setAIDSAR] = useState(false);
  const [confirmedDSAR, setConfirmedDSAR] = useState(false);

  const [caseOwner, setCaseOwner] = useState("");
  const [aiCaseOwner, setAICaseOwner] = useState(false);
  const [caseOwnerLoading, setCaseOwnerLoading] = useState(true);

  const [isSpam, setIsSpam] = useState(false);
  const [predictedSpam, setPredictedSpam] = useState({ label: "", score: 0 });
  const [aiSpam, setAISpam] = useState(false);
  const [confirmedSpam, setConfirmedSpam] = useState(false);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "start"
      }}
    >
      {children}
      <TiPencil
        className="ms-1"
        style={{
          color: "#002F5B"
        }}
      />
    </a>
  ));

  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter((child) => {
              if (!value) return !value;

              if (child.props.children.props)
                return child.props.children.props.children[1].props.children
                  .toLowerCase()
                  .startsWith(value);

              return (
                !value || child.props.children.toLowerCase().startsWith(value)
              );
            })}
          </ul>
        </div>
      );
    }
  );

  useEffect(() => {
    const id = location.search.split("=")[1];
    // Fetch the data for the id here
    axios.get(`https://api-scm.itelalabs.net/Cases/${id}`).then((response) => {
      if (response.status === 200) {
        setData(response.data);
      }
    });
  }, [location]);

  useEffect(() => {
    setPredictedCategory({
      label: data.predictions?.category?.label,
      score: data.predictions?.category?.score
    });
    if (data.predictions?.category?.label) {
      setCategory(data.predictions?.category?.label);
      setAICategory(true);
    } else if (data.details?.category) setCategory(data.details?.category);
    else setCategory("Select Category");

    setPredictedSubCategory({
      label: data.predictions?.subCategory?.label,
      score: data.predictions?.subCategory?.score
    });
    if (data.predictions?.subCategory?.label) {
      setSubCategory(data.predictions?.subCategory?.label);
      setAISubCategory(true);
    } else if (data.details?.subCategory)
      setSubCategory(data.details?.subCategory);
    else setSubCategory("Select Sub Category");

    setPredictedDSAR({
      label: data.predictions?.dsar?.label,
      score: data.predictions?.dsar?.score
    });
    if (predictedDSAR.label === "1") {
      setAIDSAR(true);
      setIsDSAR(true);
    }

    if (data.predictions?.assignee?.label) {
      setCaseOwner(data.predictions?.assignee?.label);
      setAICaseOwner(true);
    } else setCaseOwner(data.details?.assignedTo);

    setPredictedSpam({
      label: data.predictions?.spam?.label,
      score: data.predictions?.spam?.score
    });
    if (predictedSpam.label === "1") {
      setAISpam(true);
      setIsSpam(true);
    }

    setCatLoading(false);
    setSubCatLoading(false);
    setCaseOwnerLoading(false);
  }, [data]);

  const getPercentage = (score) =>
    score ? (score * 100).toFixed(0) + "%" : "...";

  const getNewCategoryPredictions = (newCategory) => {
    setCaseOwnerLoading(true);
    setSubCatLoading(true);
    const id = location.search.split("=")[1];
    // Fetch the data for the id here
    axios
      .get(`https://api-scm.itelalabs.net/Cases/${id}?category=${newCategory}`)
      .then((response) => {
        if (response.status === 200) {
          setPredictedSubCategory({
            label: response.data.predictions?.subCategory?.label,
            score: response.data.predictions?.subCategory?.score
          });
          if (response.data.predictions?.subCategory?.label) {
            setSubCategory(response.data.predictions?.subCategory?.label);
            setAISubCategory(true);
          } else if (response.data.details?.subCategory)
            setSubCategory(response.data.details?.subCategory);
          else setSubCategory("Select Sub Category");

          if (response.data.predictions?.assignee?.label) {
            setCaseOwner(response.data.predictions?.assignee?.label);
            setAICaseOwner(true);
          }
        }
        setCaseOwnerLoading(false);
        setSubCatLoading(false);
      });
  };

  const getNewSubCategoryPredictions = (newCategory) => {
    setCaseOwnerLoading(true);
    const id = location.search.split("=")[1];
    // Fetch the data for the id here
    axios
      .get(
        `https://api-scm.itelalabs.net/Cases/${id}?category=${category}&subCategory=${newCategory}`
      )
      .then((response) => {
        if (response.status === 200) {
          console.log()
          if (response.data.predictions?.assignee?.label) {
            setCaseOwner(response.data.predictions?.assignee?.label);
            setAICaseOwner(true);
          }
        }
        setCaseOwnerLoading(false);
      });
  };

  return (
    <div className="case-detail-wrapper">
      <Row>
        <span className="raise-by" style={{ marginBottom: "20px" }}>
          <Link
            to="/"
            style={{
              color: "brown",
              marginRight: "10px",
              textDecoration: "underline"
            }}
          >
            Case List
          </Link>{" "}
          {">>"}{" "}
          <u style={{ color: "black", marginLeft: "10px" }}>
            #{location.search.split("=")[1]}
          </u>
        </span>
        <Col md={8} lg={9} xl={9}>
          <div className="case-detail">
            <div className="case-header d-flex align-items-center">
              <h1 className="semi-bold-font">{data.details?.subject}</h1>

              <div className="case-actions">
                {/* <button className="btn btn-outline-secondary btn-assign">
                  <IoPlaySkipBackOutline />
                  Previous Case
                </button>
                <button className="btn btn-outline-secondary btn-skip">
                  <IoPlaySkipForwardOutline />
                  Next Case
                </button> */}
                <button className="btn btn-primary secondary-cta px-3 py-2">
                  <HiOutlineExternalLink />
                  Open in Salesforce
                </button>
              </div>
            </div>

            <div className="mt-2">
              <div>
                <div>
                  <div className="raise-by d-flex align-items-center">
                    <label className="me-2">Raised by: </label>
                    <div className="d-flex align-items-center grey-label ps-2 pe-3 py-1">
                      <span>
                        {data.details?.contactEmail?.split("")[0].toUpperCase()}
                      </span>
                      <label className="value">
                        {data.details?.contactEmail}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <label className="label-case-date me-2">Raised on: </label>
                  <label className="case-date">
                    {data.details?.createdDate
                      ? data.details?.createdDate
                      : new Date().toUTCString()}
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-4" />
            <span className="label-email-body">Email Body</span>
            <pre className="mt-2 case-description" style={{whiteSpace: "pre-wrap"}}>
              {data.details?.emailBody}
            </pre>
          </div>
        </Col>

        {/* ACTION PANEL */}
        <Col md={4} lg={3} xl={3}>
          <div className="case-customer-detail">
            <div className="side-card  px-3 py-4">
              <div className="case-descriptor">
                <label className="case-label">Category</label>
                {!catLoading ? (
                  <>
                    <Dropdown
                      className="mb-2"
                      variant="primary"
                      onSelect={(e) => {
                        setCategory(e);
                        getNewCategoryPredictions(e);
                        e === data.predictions?.category?.label
                          ? setAICategory(true)
                          : setAICategory(false);
                      }}
                    >
                      <Dropdown.Toggle as={CustomToggle}>
                        <span className="badge badge-pill bg-dark text-decoration-none">
                          {category}
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu as={CustomMenu}>
                        {categories.map((cat, index) => (
                          <Dropdown.Item key={index} eventKey={cat}>
                            {cat}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <span className="ai-content">
                      {aiCategory ? (
                        <span>
                          <label>
                            <BsStars />
                          </label>{" "}
                          Prediction Score:{" "}
                          <span className="ms-1">
                            {getPercentage(predictedCategory.score)}
                          </span>
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                  </>
                ) : (
                  <Skeleton
                    animation="wave"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  />
                )}
              </div>
              <hr />

              <div className="case-descriptor">
                <label className="case-label no-label-text-deco">
                  Sub Category
                </label>
                {!subCatLoading ? (
                  <>
                    <Dropdown
                      className="mb-2"
                      variant="primary"
                      onSelect={(e) => {
                        setSubCategory(e);
                        getNewSubCategoryPredictions(e);
                        e === data.predictions?.subCategory?.label
                          ? setAISubCategory(true)
                          : setAISubCategory(false);
                      }}
                    >
                      <Dropdown.Toggle as={CustomToggle}>
                        <span className="badge badge-pill bg-success text-decoration-none">
                          {subCategory}
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu as={CustomMenu}>
                        {subCategories.map((cat, index) => (
                          <Dropdown.Item key={index} eventKey={cat}>
                            {cat}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <span className="ai-content">
                      {aiSubCategory ? (
                        <span>
                          <label>
                            <BsStars />
                          </label>{" "}
                          Prediction Score:{" "}
                          <span className="ms-1">
                            {getPercentage(predictedSubCategory.score)}
                          </span>
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                  </>
                ) : (
                  <Skeleton
                    animation="wave"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  />
                )}
              </div>
              <hr />

              <div className="case-descriptor">
                <span>
                  <label className="case-label">Assigned To</label>
                </span>
                {!caseOwnerLoading ? (
                  <>
                    <Dropdown
                      className="mb-2"
                      variant="primary"
                      onSelect={(e) => {
                        setCaseOwner(e);
                        data.predictions?.assignee?.label === e
                          ? setAICaseOwner(true)
                          : setAICaseOwner(false);
                      }}
                    >
                      <Dropdown.Toggle as={CustomToggle}>
                        <div>
                          {data.details?.assignedTo === "" && !caseOwner ? (
                            <div>
                              <Badge bg="warning">Unassigned</Badge>
                            </div>
                          ) : (
                            <div className="assigned-value d-flex align-items-center text-decoration-none no-label-text-deco">
                              <span>{caseOwner?.split("")[0]}</span>
                              <label>{caseOwner}</label>
                            </div>
                          )}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu as={CustomMenu}>
                        {caseOwners.map((cat, index) => (
                          <Dropdown.Item key={index} eventKey={cat}>
                            <div className="assigned-value">
                              <span>{cat?.split("")[0]}</span>
                              <label className="regular">{cat}</label>
                            </div>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <span className="ai-content">
                      {aiCaseOwner ? (
                        <span>
                          <label>
                            <BsStars />
                          </label>{" "}
                          Prediction Score:{" "}
                          <span className="ms-1">
                            {getPercentage(data.predictions?.assignee?.score)}
                          </span>
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                  </>
                ) : (
                  <Skeleton
                    animation="wave"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  />
                )}
              </div>
              <hr />

              <div className="mt-4" style={{ display: "flex" }}>
                <button
                  className="btn btn-primary secondary-cta px-3 py-2 ms-auto"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                  }}
                >
                  <HiOutlineExternalLink style={{ marginRight: "10px" }} />
                  Assign in Salesforce
                </button>
              </div>
            </div>
            <hr />
            {predictedSpam.label === "1" || !predictedSpam ? (
              <div className="side-card px-3 py-4">
                <div className="d-flex flex-column align-items-start">
                  <span className="d-flex align-items-center mb-1">
                    <h4 className="semi-bold-font mb-0">
                      Potential Spam {"    "}
                    </h4>
                    {confirmedSpam ? (
                      <TiPencil
                        style={{ cursor: "pointer", color: "#002F5B" }}
                        onClick={() => setConfirmedSpam(false)}
                        className="ms-1"
                      />
                    ) : (
                      <></>
                    )}
                  </span>
                  {isSpam ? (
                    <Badge pill bg="warning" className="mb-1">
                      SPAM EMAIL
                    </Badge>
                  ) : (
                    <></>
                  )}
                </div>

                <span className="ai-content">
                  {(isSpam && aiSpam) || (!isSpam && !aiSpam) ? (
                    <span>
                      <label>
                        <BsStars />
                      </label>{" "}
                      Prediction Score:{" "}
                      <span className="ms-1">
                        {getPercentage(predictedSpam.score)}
                      </span>
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
                <div className="mt-3">
                  <span className="semi-bold-font">
                    This case{" "}
                    {predictedSpam.label === "1" ? "might" : "might not"} be a
                    Spam email.
                  </span>
                </div>
                {!confirmedSpam ? (
                  <>
                    <p>Would you like to mark this as a Spam email?</p>
                    <div className="mt-3">
                      <Row>
                        <Col md={12} lg={12} xl={12}>
                          <Button
                            className="side-card-button"
                            onClick={() => {
                              setIsSpam(true);
                              setConfirmedSpam(true);
                            }}
                          >
                            Mark as Spam
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}

            {predictedDSAR.label === "1" || !predictedDSAR ? (
              <div className="side-card mt-3 px-3 py-4">
                <div className="d-flex flex-column align-items-start">
                  <span className="d-flex align-items-center mb-1">
                    <h4 className="semi-bold-font mb-0">
                      Potential DSAR {"    "}
                    </h4>
                    {confirmedDSAR ? (
                      <TiPencil
                        style={{ cursor: "pointer", color: "#002F5B" }}
                        onClick={() => setConfirmedDSAR(false)}
                        className="ms-1"
                      />
                    ) : (
                      <></>
                    )}
                  </span>
                  {isDSAR ? (
                    <Badge pill bg="danger" className="mb-1">
                      DSAR REQUEST
                    </Badge>
                  ) : (
                    <></>
                  )}
                </div>
                <span className="ai-content">
                  {(isDSAR && aiDSAR) || (!isDSAR && !aiDSAR) ? (
                    <span>
                      <label>
                        <BsStars />
                      </label>{" "}
                      Prediction Score:{" "}
                      <span className="ms-1">
                        {getPercentage(predictedDSAR.score)}
                      </span>
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
                <div className="mt-3">
                  <span className="mt-3 semi-bold-font">
                    This case{" "}
                    {predictedDSAR.label === "1" ? "might" : "might not"} be a
                    DSAR request.
                  </span>
                </div>
                {!confirmedDSAR ? (
                  <>
                    <p>Would you like to mark this case as a DSAR Case?</p>
                    <div className="mt-4">
                      <Row>
                        <Col md={12} lg={12} xl={12}>
                          <Button
                            className="side-card-button"
                            onClick={() => {
                              setIsDSAR(true);
                              setConfirmedDSAR(true);
                            }}
                          >
                            Mark as DSAR
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Details;
