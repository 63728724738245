import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Spinner, Table, Pagination, Form, Button } from "react-bootstrap";
import Sidebar from "../components/Sidebar";

const App = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const NUMBER_ROWS = 15;

  useEffect(() => {
    // Fetch data from the API
    axios
      .get("https://api-scm.itelalabs.net/Cases/New?origin=Email")
      .then((response) => {
        if (response.data) setData(response.data);
        setIsLoading(false);
      });
  }, []);

  const totalPages = Math.ceil(data.length / NUMBER_ROWS);
  const paginatedData = data.slice(
    (currentPage - 1) * NUMBER_ROWS,
    currentPage * NUMBER_ROWS
  );

  const renderPaginationItems = () => {
    const pageNumbersToShow = 12;
    const items = [];

    // Show the first and prev buttons
    items.push(
      <Pagination.First
        key="first"
        onClick={() => setCurrentPage(1)}
        disabled={currentPage === 1}
      />
    );
    items.push(
      <Pagination.Prev
        key="prev"
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
      />
    );

    // Show the page numbers
    if (totalPages <= pageNumbersToShow) {
      for (let i = 1; i <= totalPages; i++) {
        items.push(
          <Pagination.Item
            key={i}
            active={currentPage === i}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      const half = Math.floor((pageNumbersToShow - 3) / 2);
      const startPage = Math.max(1, currentPage - half);
      const endPage = Math.min(totalPages, startPage + pageNumbersToShow - 3);

      if (startPage > 1) {
        items.push(<Pagination.Ellipsis key="ellipsis-start" />);
      }

      for (let i = startPage; i <= endPage; i++) {
        items.push(
          <Pagination.Item
            key={i}
            active={currentPage === i}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </Pagination.Item>
        );
      }

      if (endPage < totalPages) {
        items.push(<Pagination.Ellipsis key="ellipsis-end" />);
      }
    }

    // Show the next and last buttons
    items.push(
      <Pagination.Next
        key="next"
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    );
    items.push(
      <Pagination.Last
        key="last"
        onClick={() => setCurrentPage(totalPages)}
        disabled={currentPage === totalPages}
      />
    );

    return items;
  };

  return (
    <div className="App">
      <Sidebar />
      <main className="page-content">
        <div>
          <h1 className="semi-bold-font">
            Open Cases
          </h1>
        </div>
        <div className="mt-3">
          <div className="case-list-table">
          <Table striped bordered responsive hover className="case-list">
            <thead>
              <tr>
                <th>Case #</th>
                <th>Title</th>
                <th>Created Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {!isLoading ? (
                paginatedData.map((item) => (
                  <tr
                    key={item.id}
                    onClick={() => navigate(`/details?id=${item.caseNumber}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{item.caseNumber}</td>
                    <td>{item.title}</td>
                    <td>{item.createdDate}</td>
                    <td><span className="badge rounded-pill label-new">{item.status}</span></td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">
                    <center>
                      <Spinner animation="border" />
                    </center>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          </div>
          <div style={{display: "flex", justifyContent: "center"}} className="mt-2">
            {data.length > 10 && (
              <Pagination className="custom-pagination">{renderPaginationItems()}</Pagination>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default App;
